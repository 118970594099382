import React, {useEffect, useState} from "react"
import CompletePage from '../components/PageComponents/CompletePage'
import Layout from "../components/layout"
import {auth, firestore} from '../utils/firebase';
import {navigate} from 'gatsby'
import Loader from '../components/WidgetComponents/Loader'
import SEO from "../components/seo"
// import Grid from '@material-ui/core/Grid';
// import Button from '@material-ui/core/Button';

// const exampleBrand = {
//   name: 'Nice',
//   font: 'Work Sans',
//   palette: ["#e8ddcb", "#cdb380", "#036564", "#033649", "#031634"]
// }

function Brand({location}) {
  const [isLoaded, setIsLoaded] = useState(false);
  const [brand, setBrand] = React.useState(null);

  // const firebase = getFirebase();

  const brandID = location
    .search
    .slice(1)

  console.log(brandID)

  useEffect(() => {
    // if (!firebase)
    //   return;
    if (isLoaded)
      return;
    auth
      .onAuthStateChanged((user) => {
        console.log('Calling Firebase')
        // alert('User is signed in')
        // setIsSignedIn(!!user);
        const db = firestore
        let docRef = db
          .collection("brands")
          .doc(brandID);
        docRef
          .get()
          .then(function(doc) {
            if (doc.exists) {
              setBrand(doc.data())
              console.log(doc.data())
              setIsLoaded(true);
            } else {
              // doc.data() will be undefined in this case
              navigate('/404')
            }
          })
          .catch(function(error) {
            console.log("Error getting document:", error);
          });
      });
  });

  console.log(location)

  let page = (
    isLoaded
    ? <Layout>
      <SEO title={brand.name}/>
      <CompletePage brand={brand} brandID={brandID}/>
    </Layout>
    : <Loader/>)

  return (page)
}

export default Brand
